<template>
  <el-form :inline="false" >
    <el-form-item>
      <el-radio-group v-model="selectType" style="margin-bottom: 30px;" @change="selectChange">
        <el-radio-button label='1' >数据收集</el-radio-button>
        <el-radio-button label='2' >数据保存</el-radio-button>
        <el-radio-button label='3' >数据登记</el-radio-button>
        <el-radio-button label='4' >新品种选育</el-radio-button>
        <el-radio-button label='5' >制繁种</el-radio-button>
        <el-radio-button label='6' >鉴定评价</el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item>
      <resources-data-save v-if="selectType === '2'"></resources-data-save>
      <resources-data-collect v-if="selectType === '1'"></resources-data-collect>
      <p style="font-size: 45px" v-if="selectType !== '2' && selectType !== '1'">{{showInfo}}</p>
    </el-form-item>
    <!--      <el-form-item style="margin-right: 0">-->
    <!--        <el-input v-model="dataForm.key" placeholder="资源名称" clearable></el-input>-->
    <!--      </el-form-item>-->
    <!--      <el-form-item>-->
    <!--        <el-button @click="getDataList()">查询</el-button>-->
    <!--      </el-form-item>-->
  </el-form>
</template>

<script>
  import ResourcesDataSave from './resources-data-save'
  import ResourcesDataCollect from "./resources-data-collect";
  export default {
    data () {
      return {
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        detailVisible:false,
        dataListLoading: false,
        selectType: "1",
        showInfo: "数据收集：需要详细的文字说明"
      }
    },
    components: {
      ResourcesDataCollect,
      ResourcesDataSave,
    },
    created () {
    },
    methods: {
      // 获取数据列表
      selectChange () {
        if(this.selectType === '1'){
          this.showInfo = "数据收集：需要详细的文字说明"
        }
        else if(this.selectType === '2'){
          this.showInfo = "数据保存：需要详细的文字说明"
        }
        else if(this.selectType === '3'){
          this.showInfo = "数据登记：需要详细的文字说明"
        }
        else if(this.selectType === '4'){
          this.showInfo = "新品种选育：需要详细的文字说明"
        }
        else if(this.selectType === '5'){
          this.showInfo = "制繁种：需要详细的文字说明"
        }
        else if(this.selectType === '6'){
          this.showInfo = "鉴定评价：需要详细的文字说明"
        }
        this.dataListLoading = false
      },
    }
  }
</script>
